<template>
    <div id="box">
        <Header :headerIndex="2"></Header>
        <div class="container">
            <div class="index">
                <div class="part1">
                    <div class="m1340">
                        <div class="map_box ">
                            <div class="map_top flex-box mb50">
                                <div class="search_box flex-box mr20">
                                    <input type="text" class="flex-grow-1" placeholder="Membership Number">
                                    <a href="javascript:;"></a>
                                </div>
                                <a href="javascript:;" class="btn btn1 fs18 colf">200</a>
                                <a href="javascript:;" class="btn btn2 fs18 colf">500</a>
                                <a href="javascript:;" class="btn btn3 fs18 colf">1000</a>
                                <a href="javascript:;" class="btn btn4 fs18 colf">2000</a>
                                <a href="javascript:;" class="btn btn5 fs18 colf">5000</a>
                            </div>
                            <div class="map_scroll">
                                <div class="map_bottom tc">
                                    <div class="map_line map_line1 flex-box flex-center">
                                        <div class="map_item">
                                            <div class="flex-box top">
                                                <div class="flex-grow-1 colf">shbg01</div>
                                                <div class="flex-grow-1 colf opa">19300</div>
                                            </div>
                                            <div class="flex-box bottom">
                                                <div class="flex-grow-1">总</div>
                                                <div class="flex-grow-1">0</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="line1"></div>
                                    <div class="map_line map_line2 flex-box flex-around">
                                        <div class="map_item">
                                            <div class="flex-box top">
                                                <div class="flex-grow-1 colf">shbg01</div>
                                                <div class="flex-grow-1 colf opa">19300</div>
                                            </div>
                                            <div class="flex-box bottom">
                                                <div class="flex-grow-1">总</div>
                                                <div class="flex-grow-1">0</div>
                                            </div>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <a href="javascript:;" class="register fs14 colf">REGISTER</a>
                                        </div>
                                    </div>
                                    <div class="flex-box flex-around">
                                        <div class="line2"></div>
                                        <div class="line2"></div>
                                    </div>
                                    <div class="map_line map_line3 flex-box flex-around">
                                        <div class="map_item">
                                            <div class="flex-box top">
                                                <div class="flex-grow-1 colf">shbg01</div>
                                                <div class="flex-grow-1 colf opa">19300</div>
                                            </div>
                                            <div class="flex-box bottom">
                                                <div class="flex-grow-1">总</div>
                                                <div class="flex-grow-1">0</div>
                                            </div>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <a href="javascript:;" class="register fs14 colf">REGISTER</a>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <!-- <a href="javascript:;" class="register fs14 colf">REGISTER</a> -->
                                            <div class="fs12 col89 vacancy">VACANCY</div>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <!-- <a href="javascript:;" class="register fs14 colf">REGISTER</a> -->
                                            <div class="fs12 col89 vacancy">VACANCY</div>
                                        </div>
                                    </div>
                                    <div class="flex-box flex-around">
                                        <div class="line3"></div>
                                        <div class="line3"></div>
                                        <div class="line3"></div>
                                        <div class="line3"></div>
                                    </div>
                                    <div class="map_line map_line4 flex-box flex-around">
                                        <div class="map_item">
                                            <div class="flex-box top">
                                                <div class="flex-grow-1 colf">shbg01</div>
                                                <div class="flex-grow-1 colf opa">19300</div>
                                            </div>
                                            <div class="flex-box bottom">
                                                <div class="flex-grow-1">总</div>
                                                <div class="flex-grow-1">0</div>
                                            </div>
                                        </div>
                                        <div class="map_item">
                                            <div class="flex-box top">
                                                <div class="flex-grow-1 colf">shbg01</div>
                                                <div class="flex-grow-1 colf opa">19300</div>
                                            </div>
                                            <div class="flex-box bottom">
                                                <div class="flex-grow-1">总</div>
                                                <div class="flex-grow-1">0</div>
                                            </div>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <a href="javascript:;" class="register fs14 colf">REGISTER</a>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <a href="javascript:;" class="register fs14 colf">REGISTER</a>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <!-- <a href="javascript:;" class="register fs14 colf">REGISTER</a> -->
                                            <div class="fs12 col89 vacancy">VACANCY</div>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <!-- <a href="javascript:;" class="register fs14 colf">REGISTER</a> -->
                                            <div class="fs12 col89 vacancy">VACANCY</div>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <!-- <a href="javascript:;" class="register fs14 colf">REGISTER</a> -->
                                            <div class="fs12 col89 vacancy">VACANCY</div>
                                        </div>
                                        <div class="map_item">
                                            <!-- <div class="flex-box top">
                                            <div class="flex-grow-1 colf">shbg01</div>
                                            <div class="flex-grow-1 colf opa">19300</div>
                                        </div>
                                        <div class="flex-box bottom">
                                            <div class="flex-grow-1">总</div>
                                            <div class="flex-grow-1">0</div>
                                        </div> -->
                                            <!-- <a href="javascript:;" class="register fs14 colf">REGISTER</a> -->
                                            <div class="fs12 col89 vacancy">VACANCY</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            amount: '',
        };
    },
    methods: {

    },
};
</script>
